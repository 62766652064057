import {
  BrowserCacheLocation,
  EventType,
  PublicClientApplication,
  type AccountInfo,
  type AuthenticationResult,
} from "@azure/msal-browser";
import { useRuntimeConfig, useState } from "#imports";

let tokenExpirationTimer: NodeJS.Timeout;

export const useMSAuth = () => {
  const config = useRuntimeConfig();
  const msalConfig = {
    auth: {
      clientId: config.public.MICROSOFT_CLIENT_ID,
      authority: config.public.MICROSOFT_AUTHORITY,
      redirectUri: config.public.MICROSOFT_REDIRECT_URI,
      postLogoutRedirectUri: config.public.MICROSOFT_POST_LOGOUT_REDIRECT_URI,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true,
    },
    system: {
      tokenRenewalOffsetSeconds: 300,
    },
  };
  const msalInstance = useState("msalInstance", () => new PublicClientApplication(msalConfig));
  const loginRequest = {
    scopes: ["User.Read"],
  };

  async function initialize() {
    try {
      await msalInstance.value.initialize();

      // Handle redirect promise after login or redirect
      const response = await msalInstance.value.handleRedirectPromise();
      handleResponse(response);

      // Add event callback for login success
      msalInstance.value.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS) {
          setupTokenExpirationTimer();
        }
      });
    } catch (err) {}
  }

  // Handle the response after login or redirect
  function handleResponse(resp: AuthenticationResult | null) {
    if (resp?.account) {
      setupTokenExpirationTimer();
    }
  }

  // Set up timer for refreshing access token upon expiration
  function setupTokenExpirationTimer() {
    const accounts = msalInstance.value.getAllAccounts();
    if (accounts.length > 0) {
      const account = accounts[0];
      if (account.idTokenClaims && account.idTokenClaims.exp) {
        const tokenExpirationTime = account.idTokenClaims.exp * 1000;
        const currentTime = Date.now();
        const timeUntilExpiration = tokenExpirationTime - currentTime;

        clearTimeout(tokenExpirationTimer);

        tokenExpirationTimer = setTimeout(async () => {
          await refreshAccessToken(account);
        }, timeUntilExpiration);
      }
    }
  }

  // Refresh access token
  async function refreshAccessToken(account: AccountInfo) {
    try {
      await msalInstance.value.acquireTokenSilent({
        account,
        scopes: ["User.Read"],
      });
      setupTokenExpirationTimer();
    } catch (err) {
      await signOut(account.homeAccountId);
    }
  }

  // Sign in with redirect
  async function signIn() {
    try {
      await msalInstance.value.loginPopup(loginRequest);
    } catch (err) {}
  }

  // Acquire access token silently
  async function acquireTokenSilent() {
    const accounts = msalInstance.value.getAllAccounts();
    if (accounts.length > 0) {
      const account = accounts[0];
      msalInstance.value.setActiveAccount(account);
      try {
        const response = await msalInstance.value.acquireTokenSilent({
          account,
          scopes: ["User.Read"],
        });
        return response.accessToken;
      } catch (err) {
        return null;
      }
    } else {
      return null;
    }
  }

  // Get all MSAL accounts
  function getAccounts() {
    return msalInstance.value.getAllAccounts();
  }

  // Check if user is authenticated
  function isAuthenticated() {
    return getAccounts().length > 0;
  }

  // Sign out user
  async function signOut(accountId: string) {
    const account = accountId ? msalInstance.value.getAccountByHomeId(accountId) : null;
    if (account) {
      await msalInstance.value.logoutRedirect({
        account,
      });
      localStorage.clear();
    }
  }

  return {
    initialize,
    msalInstance,
    signIn,
    getAccounts,
    acquireTokenSilent,
    isAuthenticated,
    signOut,
  };
};
