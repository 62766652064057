import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPieChart, LazyStatisticCard, LazyStatisticCardCity, LazyStatisticCardConsumer, LazyStatisticCardFlight, LazyStatisticCardGender, LazyStatisticCardHotel, LazyStatisticCardInterest, LazyStatisticCardSpend, LazyCampaignPerformance } from '#components'
const lazyGlobalComponents = [
  ["PieChart", LazyPieChart],
["StatisticCard", LazyStatisticCard],
["StatisticCardCity", LazyStatisticCardCity],
["StatisticCardConsumer", LazyStatisticCardConsumer],
["StatisticCardFlight", LazyStatisticCardFlight],
["StatisticCardGender", LazyStatisticCardGender],
["StatisticCardHotel", LazyStatisticCardHotel],
["StatisticCardInterest", LazyStatisticCardInterest],
["StatisticCardSpend", LazyStatisticCardSpend],
["CampaignPerformance", LazyCampaignPerformance],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
