// plugins/google-identity.client.js
import { createPinia } from "pinia";
import { defineNuxtPlugin, useRuntimeConfig } from "#app";
import { useAuthStore } from "@/stores/authStore";

const pinia = createPinia();
const authStore = useAuthStore(pinia);

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const clientId = config.public.NUXT_PUBLIC_GOOGLE_CLIENT_ID;

  const loadGisScript = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = () => resolve();
      script.onerror = () => reject(new Error("Failed to load Google Identity Services script"));
      document.head.appendChild(script);
    });
  };

  const initializeGoogleIdentity = async (): Promise<void> => {
    try {
      await loadGisScript();
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: (response: { credential: string }) => {
          // Handle the response from Google
          authStore.authenticateUserSSO(response.credential, "google");
        },
        locale: "en", // Set the locale to English
      });
    } catch (error) {}
  };

  return {
    provide: {
      googleIdentity: {
        initialize: initializeGoogleIdentity,
        renderButton(elementId: string, options: any) {
          const element = document.getElementById(elementId);
          if (element) {
            window.google.accounts.id.renderButton(element, options);
          }
        },
        prompt() {
          window.google.accounts.id.prompt();
        },
      },
    },
  };
});
